<template>
  <ab-header></ab-header>
</template>

<script>
import { mapActions, mapState } from "vuex"
import ws from "../../utils/ws";

export default {
  data() {
    return {
      menuList: [],
      noticeList: {}
    };
  },

  components: {

  },

  created () {
    this.menuList = (this.$utils.getSessionStorage('menuList') || []).filter(e => e.isShow)
    // this.getMessageList()
    // ws.init()
    // ws.showNotice = this.showNotice
    // ws.closeNotice = this.closeNotice
    // ws.getMessageList = this.getMessageList
  },

  mounted() {

  },

  computed: {
    messageList() {
      return this.$store.state.common.messageList;
    }
  },

  watch: {

  },

  methods: {
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/p/user/logout"),
            method: "post",
            data: this.$http.adornData(),
          }).then(({ data }) => {
            if (data && data.code === "C0000") {
              this.$utils.clearLoginInfo();
              this.$utils.clearAllSessionStorage();
              this.$router.options.isAddDynamicMenuRoutes = false
              this.$router.push({
                name: "login",
                replace: true,
              });
            }
          });
        })
        .catch(() => {});
    },


    getMessageList() {
      this.$http({
        url: this.$http.adornUrl("/p/msgInmail/getCurrentMsg"),
        method: "post",
      }).then((res) => {
        if (res.data && res.data.code === "C0000") {
          //this.messageList = res.data.result
          this.$store.commit("common/SET_MESSAGE_LIST", res.data.result);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 单个点击
    msgItemClick(id) {
      this.$router.push({ path: "/message-center-unread" });
    },

    // 标记已读
    markReaded(id) {
      let ids =
        id && !isNaN(id)
          ? [id]
          : this.messageList.map((e) => {
              return e.id;
            });
      if (ids.length) {
        this.$http({
          url: this.$http.adornUrl("/p/msgInmail/consumeMsg"),
          method: "post",
          data: ids,
        }).then((res) => {
          if (res.data && res.data.code === "C0000") {
            this.getMessageList();
          } else {
            this.$message.error(res.data.message);
          }
        });
        // 关闭消息框
        ids.forEach((e) => {
          this.closeNotice(e);
        });
      }
    },
  
    // 显示消息框
    showNotice(data) {
      if (data && data.typeName === "EVENT") {
        this.$initSessionStorageData.getUserInfo().then(() => {
          if (data.title == "个人认证") {
            //this.$refs.certificate.getCurClientRegisterStep()
            window.location.reload(true)
          } else if (data.title == "企业认证") {
            window.location.reload(true)
           // this.$refs.certificate.getCurClientRegisterStep()
          } else if (data.title == "签约成功") {
            this.$utils.setSessionStorage('stepDone', true)
            window.location.reload(true)
          }
        })
        return
      }
      this.getMessageList();
      const h = this.$createElement;
      this.noticeList[data.id] = this.$notify({
        dangerouslyUseHTMLString: true,
        iconClass: "m-icon",
        customClass: "custom-message",
        duration: 10000,
        position: "bottom-right",
        title: data.title,
        message: h("div", null, [
          h(
            "div",
            { class: "notify-text", style: "word-break: break-all;" },
            data.content
          ),
          h("div", { class: "notify-button" }, [
            h(
              "button",
              {
                on: {
                  click: this.noticeReaded,
                },
                attrs: {
                  class: "el-button el-button--default el-button--small",
                  id: data.id,
                },
              },
              "忽略"
            ),
            h(
              "button",
              {
                on: {
                  click: this.noticeDetail,
                },
                attrs: {
                  class:
                    "el-button el-button--default el-button--small el-button--primary ",
                  id: data.id,
                  url: data.msgUrl,
                  typeName: data.typeName,
                  billId: data.billId,
                },
              },
              "详情"
            ),
          ]),
        ]),
      });
    },
  
    // 关闭消息框
    closeNotice(id) {
      if (id && !isNaN(id)) {
        if (this.noticeList[id]) this.noticeList[id].close();
      } else {
        for (let i in this.noticeList) {
          this.noticeList[i].close();
        }
      }
    },
  
    // 忽略
    noticeReaded(event) {
      const id = event.target.getAttribute("id");
      this.markReaded(id);
    },
  
    // 详情
    noticeDetail() {
      const id = event.target.getAttribute("id");
      const url = event.target.getAttribute("url");
      const typeName = event.target.getAttribute("typeName");
      const billId = event.target.getAttribute("billId");
      this.markReaded(id);
      if (typeName === "FILE") {
        this.$utils.listDownLoad({ id: billId });
      } else if (url) {
        console.log(url);
        let route = this.$utils.getTabDataByUrl(url, true);
        console.log(route);
        if (route.type === "router") {
          this.$router.push({
            path: route.path,
            query: route.query,
          });
        } else {
          window.open(route.path);
        }
      } else {
        this.$router.push({ name: "message-center-all" })
      }
    }
  }
}
</script>

<style lang="scss">
.site-wrapper {
  .header {
    z-index: 1030;
  }
}
</style>
